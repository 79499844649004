@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;700&display=swap');




.sidebar-title{
    margin-top: 15px;
    font-size: 20px;
    border-bottom: 4px solid grey;
    padding-bottom: 5px;
}

.customer-box{
    background: gainsboro;
    padding: 15px;
    border-radius: 26px;
}


.user-box{
    background: #7f7fff;
    padding: 15px;
    border-radius: 26px;
    color: white;
}

.chat-title p{
    font-size: 22px;
    border-bottom: 4px solid grey;
    padding-bottom: 5px;
    margin-top: 11px;
}

.input-message{
    width: 100%;
    height: 50px;
    color: grey;
    padding: 10px;
}

.chat-actions{
    width: 100%;
    bottom: -20px;
    height: 70px;
    background-color: white;
}

.time{
    position: absolute;
    right: 30px;
    color: white;
}

.chat-title{
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    border-bottom: 5px solid #3b3bb5;
    padding-bottom: 5px;
}

