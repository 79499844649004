@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;300;400;700&display=swap);
body {
  background: rgb(1,108,177);
  background: linear-gradient(90deg, rgba(1,108,177,1) 0%, rgba(0,126,207,1) 35%, rgba(0,212,255,1) 100%);
}

.App {
  text-align: center;
  height: 100vh;
}

.logout {
  color: white !important;
  margin-left: 10px;
}

.logo {
  color: red;
  font-weight: bold;
  letter-spacing: 10px;
  font-size: 50px;
}

.user-box {
  background: #7f7fff;
  padding: 15px;
  border-radius: 26px;
  color: white;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}
.app_icon {
  width: 70px;
  height: 70px;
}
.redep {
  display: block;
}
.block {
  width: 100%;
  background: white;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.apps-title {
  letter-spacing: 1px;
  font-weight: bold;
  display: inline-block;
  padding-bottom: 6px;
  text-align: center;
}

.app_container {
  transition: 1s ease-in-out;
}

.app_title {
  font-weight: bold;
  padding-top: 5px;
  text-align: center;
  display: inline-block;
}

.app_container:hover {
  transition: 1s ease-in-out;
  background: #efefef;
  cursor: pointer;
}

.control-logo {
  font-weight: bold;
  color: white;
  padding: 20px;
  font-size: 20px;
  letter-spacing: 2px;
}

.login_btn {
  background: black;
  color: white;
}

.login-container {
  padding-top: 10%;
}

.MuiInputLabel-filled {
  color: white !important;
}

#okta-sign-in.auth-container.main-container {
  background: none !important;
  border: 0 !important;
}

.animated-linear {
  background: linear-gradient(226deg, #006bff, #0f57bb, #5192ec, #4551ff);
  background-size: 800% 800%;
  animation: AnimationName 16s ease infinite;
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.control-header {
}

.infobox-error {
  color: black !important;
}

#okta-sign-in .auth-content {
  padding: 0 !important;
}

.okta-form-title {
  color: white !important;
}

.original-doc {
  position: absolute;
  transform: rotateZ(328deg);
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 27px;
  margin-left: 41px;
  background: white;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}
.original-doc2 {
  position: absolute;
  transform: rotateZ(328deg);
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 105px;
  margin-left: 162px;
  font-size: 61px;
  background: white;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

.sign-title {
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.border-grey {
  border: 2px solid #e6e6e6;
}

#okta-sign-in {
  margin-top: 0px !important;
  color: white !important;
  width: 100% !important;
  margin: 0 !important;
}

#okta-sign-in.no-beacon .auth-header {
  padding: 0 !important;
}

.okta-form-input-error {
  color: white !important;
}

.auth-footer a {
  color: white !important;
}

.okta-form-label {
  color: white !important;
  text-align: left;
}

.okta-sign-in-header {
  border: 0 !important;
}

.overlay {
  height: 100vh;
  opacity: 0.9;
  padding-top: 10%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.img-with-text {
  text-align: center;
  width: 100px;
  padding-top: 5px;
}
.img-with-text img {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.user-box{
    background: #7f7fff;
    padding: 15px;
    border-radius: 26px;
    color: white;
}
.user-info-container {
  z-index: 99;
  top: 100px;
  position: fixed;
  right: 8px;
  height: 210px;
  width: 300px;
  background-color: #007bff;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 2.5px 2.5px rgba(30, 30, 30, 0.2);
  border: 0.1px solid rgba(0,0,0 0.2);
}

.header-photo-container {
  padding: 10px 10px;
  position: relative;
}

.info-box-container {
  flex-grow: 1;
  justify-content: center;
  padding: 5px 5px;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.header-rounded-logo {
  background-position: center center, center center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  display: block;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
}

.text {
  margin-top: 5px;
  color: white;
}

.portal-header {
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 20px;

    box-shadow: 2px 3px rgba(230, 230, 230, 0.4);

    background-color: rgb(252, 252, 252);
}

.img-with-text {
    margin-left: 50px;
    margin-right: 50px;
}

@media (max-width: 700px) {
    .img-with-text {
        margin-left: 1px;
        margin-right: 1px;
    }
}
.app_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
}

.app_container:hover {
    background-color: transparent;
}

.app_box {
    background: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 15%;
    width: 15%;
    padding: 10px;
    padding-top: 25px;
    align-self: center;
    margin: 20px;

    border: 0.5px solid rgba(221, 221, 221, 0.2);
    border-radius: 50px;
    box-shadow: 2px 3px rgba(230, 230, 230, 0.4);
}

.app_image_container {
    align-content: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
}

.app_link_container {
    align-content: center;
    justify-content: center;
    text-align: center;
}

.app_title {
    text-align: center;
}

.app_box:hover {
    background-color: rgb(252, 252, 252);
    animation-name: hoverChange;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes hoverChange {
    0% { background-color: rgb(255, 255, 255) }
    50% { background-color: rgb(230, 230, 230 ) }
    100% { background-color: rgb( 220, 220, 220 ) }
}

@media (max-width: 700px) {
    .app_box {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        height: 50%;
        width: 40%;
        padding: 10px;
        align-self: center;
        margin: 10px;
        padding: 10px;
        padding-top: 25px;
    }
    
}
.sidebar-title{
    margin-top: 15px;
    font-size: 20px;
    border-bottom: 4px solid grey;
    padding-bottom: 5px;
}

.customer-box{
    background: gainsboro;
    padding: 15px;
    border-radius: 26px;
}


.user-box{
    background: #7f7fff;
    padding: 15px;
    border-radius: 26px;
    color: white;
}

.chat-title p{
    font-size: 22px;
    border-bottom: 4px solid grey;
    padding-bottom: 5px;
    margin-top: 11px;
}

.input-message{
    width: 100%;
    height: 50px;
    color: grey;
    padding: 10px;
}

.chat-actions{
    width: 100%;
    bottom: -20px;
    height: 70px;
    background-color: white;
}

.time{
    position: absolute;
    right: 30px;
    color: white;
}

.chat-title{
    font-family: 'Roboto Slab', serif;
    font-size: 20px;
    border-bottom: 5px solid #3b3bb5;
    padding-bottom: 5px;
}


.info-container {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.photo-container {
  flex: 1 1;
  padding-bottom: 5px;
  position: relative;
}

.text-container {
  flex: 1 1;
  padding-bottom: 5px;
}

.rounded-logo {
  background-position: center center, center center;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(210, 210, 210);
  border-radius: 100%;
  display: block;
}

.avatar-button {
  width: 100px;
  height: 40px;
  font-size: 12px;
}

.avatar-container {
  align-items: center;
  text-align: center;
}

.password-button {
  margin-top: 5px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 10px;
  margin: 5px;

  border: 1px solid rgba( 20, 20, 20, 0.01);
  border-radius: 5px;
  padding: 5px;
}

.app-box p {
  text-align: center;
}

.section-header {
  margin-left: 10px;
}

