.user-info-container {
  z-index: 99;
  top: 100px;
  position: fixed;
  right: 8px;
  height: 210px;
  width: 300px;
  background-color: #007bff;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  box-shadow: 2.5px 2.5px rgba(30, 30, 30, 0.2);
  border: 0.1px solid rgba(0,0,0 0.2);
}

.header-photo-container {
  padding: 10px 10px;
  position: relative;
}

.info-box-container {
  flex-grow: 1;
  justify-content: center;
  padding: 5px 5px;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.header-rounded-logo {
  background-position: center center, center center;
  background-size: 100%;
  background-repeat: no-repeat;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  display: block;
  width: 80px;
  height: 80px;
  max-width: 80px;
  max-height: 80px;
}

.text {
  margin-top: 5px;
  color: white;
}
