.app_container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background-color: transparent;
}

.app_container:hover {
    background-color: transparent;
}

.app_box {
    background: white;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 15%;
    width: 15%;
    padding: 10px;
    padding-top: 25px;
    align-self: center;
    margin: 20px;

    border: 0.5px solid rgba(221, 221, 221, 0.2);
    border-radius: 50px;
    box-shadow: 2px 3px rgba(230, 230, 230, 0.4);
}

.app_image_container {
    align-content: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0px;
}

.app_link_container {
    align-content: center;
    justify-content: center;
    text-align: center;
}

.app_title {
    text-align: center;
}

.app_box:hover {
    background-color: rgb(252, 252, 252);
    animation-name: hoverChange;
    animation-duration: 4s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes hoverChange {
    0% { background-color: rgb(255, 255, 255) }
    50% { background-color: rgb(230, 230, 230 ) }
    100% { background-color: rgb( 220, 220, 220 ) }
}

@media (max-width: 700px) {
    .app_box {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        height: 50%;
        width: 40%;
        padding: 10px;
        align-self: center;
        margin: 10px;
        padding: 10px;
        padding-top: 25px;
    }
    
}