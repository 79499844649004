.info-container {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.photo-container {
  flex: 1;
  padding-bottom: 5px;
  position: relative;
}

.text-container {
  flex: 1;
  padding-bottom: 5px;
}

.rounded-logo {
  background-position: center center, center center;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 150px;
  width: 150px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(210, 210, 210);
  border-radius: 100%;
  display: block;
}

.avatar-button {
  width: 100px;
  height: 40px;
  font-size: 12px;
}

.avatar-container {
  align-items: center;
  text-align: center;
}

.password-button {
  margin-top: 5px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-box {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 10px;
  margin: 5px;

  border: 1px solid rgba( 20, 20, 20, 0.01);
  border-radius: 5px;
  padding: 5px;
}

.app-box p {
  text-align: center;
}

.section-header {
  margin-left: 10px;
}
