.portal-header {
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 20px;

    box-shadow: 2px 3px rgba(230, 230, 230, 0.4);

    background-color: rgb(252, 252, 252);
}

.img-with-text {
    margin-left: 50px;
    margin-right: 50px;
}

@media (max-width: 700px) {
    .img-with-text {
        margin-left: 1px;
        margin-right: 1px;
    }
}